import { useCallback, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { getMediasInfos, getPDMRanking, PDMRankingApiResponse } from "../../../api/MediasRequests";
import { LoadingState } from "../../../navigation/LoadingState";
import { Interval } from "../../../navigation/tabs/Tabs";
import { Area } from "../../../navigation/tree/Area";
import {Trans} from "react-i18next"
import classes from "../../Statistics.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { MediaInfos } from "../../../models/MediaInfos";

interface IProps {
    area: Area
    nodePath: string,
    startDateTimestamp: number,
    endDateTimestamp: number,
    interval: Interval,
    mediaspotSerials?: string[]
}

export const PDMRanking = (props: IProps) => {

    const [mediasRanking, setPDMRanking] = useState<PDMRankingApiResponse|undefined>(undefined)
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING)
      
    const loadPDMRanking = async() => {
        setLoadingState(LoadingState.LOADING)
        const mediasRanking = await getPDMRanking(props.startDateTimestamp, props.endDateTimestamp, props.nodePath, props.area.mediaspot?.serial, props.mediaspotSerials)
        if(mediasRanking.data){
            setPDMRanking(mediasRanking.data)
            setLoadingState(LoadingState.LOADED)
        }else {
            setLoadingState(LoadingState.ERROR)
        }

    }

    // Each time props are updated, reload data
    useEffect(() => {
        loadPDMRanking()
    }, [props.startDateTimestamp, props.endDateTimestamp, props.nodePath, props.interval])

    
    return (
        <>
         {
            loadingState === LoadingState.LOADING ?
                <div className={classes.StatisticsLoaderContainer}>
                    <Loader type="Oval" color="#4185F4" height={50} width={50}/>
                </div> : null
        }
        {
            loadingState === LoadingState.ERROR ?
                <div className={classes.StatisticsErrorContainer}>
                    <label><Trans>AnErrorOccurredDuringLoading</Trans>...</label>
                    <br />
                    <button className={classes.RetryButton} onClick={() => loadPDMRanking()}><FontAwesomeIcon icon={faRedo} size={"sm"} color={"#404040"}/>&nbsp;&nbsp;<Trans>Reload</Trans></button>
                </div> : null
        }
            
        {
            loadingState === LoadingState.LOADED && mediasRanking ?

                mediasRanking.length === 0 ? <div className={classes.StatisticsNoDataContainer}>
                    <label><Trans>NoDataOnThisPeriod</Trans></label>
                </div> :

                <table>
                    <thead>
                        <tr>
                            <th>Edition</th>
                            <th>Nombre de lectures</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            mediasRanking.sort((a, b) => {                                
                                if(a.media > b.media)
                                    return -1
                                return 1
                            }).map(mediaRanking => {
                                const year = mediaRanking.media.substring(0, 4)
                                const month = mediaRanking.media.substring(4, 6)
                                const day = mediaRanking.media.substring(6, 8)
                                
                                return (
                                    <tr>
                                        <td>Edition du {day}/{month}/{year}</td>
                                        <td>{mediaRanking.count}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                : undefined
            }
        </>
    )
}