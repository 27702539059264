import { useCallback, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { getMediasInfos, getMediasRanking, MediaRankingApiResponse } from "../../../api/MediasRequests";
import { LoadingState } from "../../../navigation/LoadingState";
import { Interval } from "../../../navigation/tabs/Tabs";
import { Area } from "../../../navigation/tree/Area";
import {Trans} from "react-i18next"
import classes from "../../Statistics.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { MediaInfos } from "../../../models/MediaInfos";

interface IProps {
    area: Area
    nodePath: string
    startDateTimestamp: number
    endDateTimestamp: number
    interval: Interval
    mediaspotSerials?: string[]
}

export const MediasRanking = (props: IProps) => {

    const [mediasRanking, setMediasRanking] = useState<MediaRankingApiResponse|undefined>(undefined)
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING)

    const [mediasInfos, setMediasInfos] = useState<MediaInfos[]>([])
      
    const loadMediaRanking = async() => {
        setLoadingState(LoadingState.LOADING)
        // const mediasRanking = await getMediasRanking(props.startDateTimestamp, props.endDateTimestamp, props.nodePath, props.area.mediaspot?.serial, props.mediaspotSerials)
        const mediasRanking = await getMediasRanking(props.startDateTimestamp, props.endDateTimestamp, props.nodePath, props.area.mediaspot?.serial)
        if(mediasRanking.data){
            setMediasRanking(mediasRanking.data)
            
            const mediasInfosResponse = await getMediasInfos(mediasRanking.data.map(it => it.media).map(it => {

                const mediaFileName = it.split("/")[it.split("/").length - 1]
                let reformattedName = mediaFileName.replaceAll("_720p_1M_video.m3u8", "").toLowerCase()
                return reformattedName.charAt(0).toUpperCase() + reformattedName.slice(1);
            }))
            if(mediasInfosResponse && mediasInfosResponse.data){
                setMediasInfos(mediasInfosResponse.data)
            }
            
            setLoadingState(LoadingState.LOADED)
        }else {
            setLoadingState(LoadingState.ERROR)
        }

    }

    // Each time props are updated, reload data
    useEffect(() => {
        loadMediaRanking()
    }, [props.startDateTimestamp, props.endDateTimestamp, props.nodePath, props.interval])

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const getTooltip = (path: string, mediaInfo?: MediaInfos) => {
        let result = ""

        if(mediaInfo){
            if(mediaInfo.actors){
                result += "Acteurs : " + mediaInfo.actors
                result += "<br />"
            }

            if(mediaInfo.director){
                result += "Réalisateur : " + mediaInfo.director
                result += "<br />"
            }

            result += "Durée : " + mediaInfo.duration + "min" 
            result += "<br />"
        }

        result += "Chemin : " + path
        return result
    }

    return (
        <>
         {
            loadingState === LoadingState.LOADING ?
                <div className={classes.StatisticsLoaderContainer}>
                    <Loader type="Oval" color="#4185F4" height={50} width={50}/>
                </div> : null
        }
        {
            loadingState === LoadingState.ERROR ?
                <div className={classes.StatisticsErrorContainer}>
                    <label><Trans>AnErrorOccurredDuringLoading</Trans>...</label>
                    <br />
                    <button className={classes.RetryButton} onClick={() => loadMediaRanking()}><FontAwesomeIcon icon={faRedo} size={"sm"} color={"#404040"}/>&nbsp;&nbsp;<Trans>Reload</Trans></button>
                </div> : null
        }
            
        {
            loadingState === LoadingState.LOADED && mediasRanking ?

                mediasRanking.length === 0 ? <div className={classes.StatisticsNoDataContainer}>
                    <label><Trans>NoDataOnThisPeriod</Trans></label>
                </div> :

                <table>
                    <thead>
                        <tr>
                            <th>Média</th>
                            <th>Nombre de lectures</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            mediasRanking.map(mediaRanking => {
                                const mediaFileName = mediaRanking.media.split("/")[mediaRanking.media.split("/").length - 1]
                                let reformattedName = mediaFileName.replaceAll("_720p_1M_video.m3u8", "").toLowerCase()
                                reformattedName = reformattedName.charAt(0).toUpperCase() + reformattedName.slice(1);

                                const mediaInfo = mediasInfos.find(it => it.id.toString() === reformattedName)
                        
                                if(mediaInfo){
                                    reformattedName = mediaInfo.name
                                }
                                return (
                                    <tr>
                                        <td data-event="click" data-text-color={"#ffffff"} data-place={"bottom"} data-class={classes.tooltip} data-border={true} data-border-color={"#c3bdb9"} data-background-color={"black"} data-multiline={true} data-tip={getTooltip(mediaRanking.media, mediaInfo)} style={{cursor: "help"}}>
                                            {reformattedName}
                                        </td>
                                        <td>{mediaRanking.count}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                : undefined
            }
        </>
    )
}