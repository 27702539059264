import { ChartDataSets, ChartPoint } from "chart.js";
import { DataConsumptionEvolutionResponse } from "../../../api/GSMRequests";
import { MaterialColors } from "../../../helpers/Colors";
import { EvolutionDataset } from "../EvolutionDataset";

export const getDataConsumptionEvolutionDataset = (data: DataConsumptionEvolutionResponse, currentValueOnly: boolean = false):EvolutionDataset => {
    
    const dataset: ChartDataSets = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: "rgba(220,4,151,0)",
        label: "Data",
        lineTension: 0,
        data: [],
        type: "line"
    };

    const dataset2: ChartDataSets = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: MaterialColors[2],
        label: "Data",
        lineTension: 0,
        data: [],
        type: "bar"
    };
    
    const dates:Array<Date> = new Array<Date>()
   
    const labels:Array<string> = new Array<string>()

    data.forEach(it => {

        const dateString:string = it.date
        const date:Date = new Date(dateString)
        
        dates.push(date);
    
        (dataset.data as ChartPoint[]).push({
            x: date,
            y: it.totalUsed / 1024 / 1024
        });

        (dataset2.data as ChartPoint[]).push({
            x: date,
            y: it.used / 1024 / 1024
        });

        labels.push(date.toLocaleDateString())
        
    })
    console.log("dataset", dataset)
    console.log("dataset2", dataset2)

    return {
        datasets: [dataset, dataset2],
        dates: dates,
        error: undefined,
        //labels: undefined,
        labels: labels,
        maxValue: undefined
    }
}