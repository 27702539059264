import { useEffect, useState } from "react"
import classes from "../stats/Statistics.module.css"
import { Interval } from "../navigation/tabs/Tabs";
import {subDays} from "date-fns"
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { Graph, GraphType } from "../graph/Graph";
import { RutxAuth } from "../stats/maintenance/rutxauth/RutxAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip';

const InternetAccess = () => {

    
    const startDate = subDays(Date.now(), 6)
    startDate.setHours(0,0,0,0);

    const endDate = Date.now()

    const ResponsiveReactGridLayout = WidthProvider(Responsive);
    const [layouts, setLayouts] = useState<Layouts>({})

    const [startPeriodTimestamp, setStartPeriodTimestamp] = useState<number>(startDate.getTime())
    const [endPeriodTimestamp, setEndPeriodTimestamp] = useState<number>(endDate)

    const [interval, setInterval] = useState<Interval>(Interval.DAY)

    // Reload tooltip is required to get it 100% functional
    useEffect(() => {
        ReactTooltip.rebuild()
    })


    return (
        <>
        
            <ResponsiveReactGridLayout
                rowHeight={100}
                cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                layouts={layouts}
                onLayoutChange={(_currentLayout: Layout[], allLayouts: Layouts) => {
                    // Check to prevent infinite refresh
                    if(JSON.stringify(allLayouts) !== JSON.stringify(layouts)){
                        setLayouts(allLayouts)
                    }
                }}>

                <div className={classes.ChartContainer} key={"wifi_connections_count"} data-grid={{ w: 4, h: 3, x: 0, y: 0, static: true }}>
                    <label>Évolution du nombre de sessions <span data-event={"click"} data-text-color={"#ffffff"} data-place={"bottom"}  data-border={true} data-border-color={"#000000"} data-background-color={"black"} data-multiline={true} data-tip={"Représente le nombre de connexions total avec saisie d'email"}><FontAwesomeIcon icon={faInfoCircle}/></span></label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>            
                        <div className={classes.StatisticsGroupContent}>
                            <Graph
                                    preventUpdateAnimations={false}
                                    graphType={GraphType.WIFI_CONNECTIONS_COUNT_EVOLUTION}
                                    startDatePeriodTimestamp={startPeriodTimestamp}
                                    interval={interval}
                                    nodePath={""}
                                    endDatePeriodTimestamp={endPeriodTimestamp}/>
                        </div>
                    </div>
                </div>

                <div className={classes.ChartContainer} key={"sessions_count_evolution"} data-grid={{ w: 4, h: 3, x: 4, y: 0, static: true }}>
                    <label>Évolution du nombre de visiteurs uniques <span data-event={"click"} data-text-color={"#ffffff"} data-place={"bottom"}  data-border={true} data-border-color={"#000000"} data-background-color={"black"} data-multiline={true} data-tip={"Représente le nombre de connexions avec saisie d'email d'appareils (adresse MAC) différents"}><FontAwesomeIcon icon={faInfoCircle}/></span></label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>            
                        <Graph
                            preventUpdateAnimations={false}
                            graphType={GraphType.SESSIONS_COUNT_EVOLUTION}
                            startDatePeriodTimestamp={startPeriodTimestamp}
                            interval={interval}
                            nodePath={""}
                            endDatePeriodTimestamp={endPeriodTimestamp}/>

                    </div>
                </div>


                <div className={classes.ChartContainer} key={"vehicles_count_evolution"} data-grid={{ w: 4, h: 3, x: 8, y: 0, static: true }}>
                    <label>Évolution du nombre de véhicules <span data-event={"click"} data-text-color={"#ffffff"} data-place={"bottom"}  data-border={true} data-border-color={"#000000"} data-background-color={"black"} data-multiline={true} data-tip={"Représente le nombre véhicules ayant demarré par jours ( > 2min)"}><FontAwesomeIcon icon={faInfoCircle}/></span></label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>            
                        <div className={classes.StatisticsGroupContent}>
                            <Graph
                                    preventUpdateAnimations={false}
                                    graphType={GraphType.VEHICLES_COUNT_EVOLUTION}
                                    startDatePeriodTimestamp={startPeriodTimestamp}
                                    interval={interval}
                                    nodePath={""}
                                    endDatePeriodTimestamp={endPeriodTimestamp}/>
                        </div>
                    </div>
                </div>




                

                <div className={classes.ChartContainer} key={"monitor_hits_count_evolution"} data-grid={{ w: 6, h: 3, x: 0, y: 3, static: true }}>
                    <label>Evolution du nombre de hits <span data-event={"click"} data-text-color={"#ffffff"} data-place={"bottom"}  data-border={true} data-border-color={"#000000"} data-background-color={"black"} data-multiline={true} data-tip={"Représente le nombre de remontées de données de monitoring de l'ensemble des véhicules sur les 7 derniers jours"}><FontAwesomeIcon icon={faInfoCircle}/></span></label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>            
                        <div className={classes.StatisticsGroupContent}>
                            <Graph
                                    preventUpdateAnimations={false}
                                    graphType={GraphType.MONITOR_HITS_COUNT_EVOLUTION}
                                    startDatePeriodTimestamp={startPeriodTimestamp}
                                    interval={Interval.HOUR}
                                    nodePath={""}
                                    endDatePeriodTimestamp={endPeriodTimestamp}/>
                        </div>
                    </div>
                </div>

                <div className={classes.ChartContainer} key={"rutx_auth_messages"} data-grid={{ w: 6, h: 3, x: 6, y: 3, static: true }}>
                    <label>Connexions administration du routeur <span data-event={"click"} data-text-color={"#ffffff"} data-place={"bottom"}  data-border={true} data-border-color={"#000000"} data-background-color={"black"} data-multiline={true} data-tip={"Nombre de tentatives de connexions/status au routeur RUTX sur les 7 derniers jours"}><FontAwesomeIcon icon={faInfoCircle}/></span></label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>            
                        <div className={classes.StatisticsGroupContent}>
                            <RutxAuth
                                area={undefined}
                                startDateTimestamp={startPeriodTimestamp}
                                nodePath={""}
                                endDateTimestamp={endPeriodTimestamp}/>
                        </div>
                    </div>
                </div>

                <div className={classes.ChartContainer} key={"nb_hits_rutx_hotspot"} data-grid={{ w: 4, h: 3, x: 0, y: 6, static: true }}>
                    <label>Traffic réseau (nombre de paquets)</label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>            
                        <div className={classes.StatisticsGroupContent}>
                            <Graph
                                preventUpdateAnimations={false}
                                graphType={GraphType.NETWORK_HITS_PER_NET}
                                startDatePeriodTimestamp={startPeriodTimestamp}
                                interval={interval}
                                nodePath={""}
                                endDatePeriodTimestamp={endPeriodTimestamp}/>
                        </div>
                    </div>
                </div>


                <div className={classes.ChartContainer} key={"total_data_consumption"} data-grid={{ w: 4, h: 3, x: 4, y: 6, static: true }}>
                    <label>Consommation data mobile totale <span data-event={"click"} data-text-color={"#ffffff"} data-place={"bottom"}  data-border={true} data-border-color={"#000000"} data-background-color={"black"} data-multiline={true} data-tip={"Représente l'évolution de la quantité de données mobiles consommée sur les 7 derniers jours pour l'ensemble du parc de véhicules"}><FontAwesomeIcon icon={faInfoCircle}/></span></label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>            
                        <div className={classes.StatisticsGroupContent}>
                            <Graph
                                preventUpdateAnimations={false}
                                graphType={GraphType.TOTAL_DATA_USAGE_EVOLUTION}
                                startDatePeriodTimestamp={startPeriodTimestamp}
                                interval={interval}
                                nodePath={""}
                                endDatePeriodTimestamp={endPeriodTimestamp}/>
                        </div>
                    </div>
                </div>
    

                <div className={classes.ChartContainer} key={"nginx_requests_per_statuses"} data-grid={{ w: 4, h: 3, x: 8, y: 6, static: true }}>
                    <label>Évolution des codes HTTP</label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>            
                        <div className={classes.StatisticsGroupContent}>
                            <Graph
                                preventUpdateAnimations={false}
                                graphType={GraphType.NGINX_STATUS_COUNT_EVOLUTION}
                                startDatePeriodTimestamp={startPeriodTimestamp}
                                interval={interval}
                                nodePath={""}
                                endDatePeriodTimestamp={endPeriodTimestamp}/>
                        </div>
                    </div>
                </div>
                
                
            </ResponsiveReactGridLayout>
            <ReactTooltip />
        </>)
    
}


export default InternetAccess