import { ChartConfiguration } from "chart.js"
import "chartjs-plugin-annotation"
import i18n from "../../../i18n"
import {Interval} from "../../../navigation/tabs/Tabs"
import xAxeTimeDefaultProps from "../XAxeTimeDefaultProps"

type Thresold = {
    key: string,
    color: string,
    text: string,
    labelXOffset: number,
    value: number
}

const warningThresold: Thresold = {
    key: "WARNING",
    color: "orange",
    text: i18n.t("Seuil 50Go"),
    labelXOffset: 0,
    value: 50
}

const limitThresold: Thresold = {
    key: "LIMIT",
    color: "red",
    text: i18n.t("Limite 100Go"),
    labelXOffset: 0,
    value: 100
}


export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number, noLimit: boolean = false):ChartConfiguration => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    const props:ChartConfiguration = {
        type: 'bar',
        data: {},
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDateTimestamp, endDateTimestamp)
                ],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('GBytes')
                    },
                    ticks: {
                        max: noLimit === true ? undefined : 110,
                        min: 0,
                        stepSize: noLimit === true ? 10 : undefined,
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        if(data !== undefined && data["datasets"] !== undefined && data["datasets"].length > 0 && data['datasets'][0]['data'] !== undefined && tooltipItem !== undefined && tooltipItem['index'] !== undefined && data['datasets'][0]['data'][tooltipItem['index']] !== undefined){
                            const dataset = data['datasets'][tooltipItem.datasetIndex!]['data']![tooltipItem['index']] as {x: Date, y: number}
                            const title = data['datasets'][tooltipItem.datasetIndex!].label

                            let value = dataset.y
                            if(value < 1 && value >= (1/1024)){
                                return title + " : " + (dataset.y * 1024).toFixed(1) + "MB";
                            }else if(value < 1 && value >= (1/1024/1024)){
                                return title + " : " + (dataset.y * (1024*1024)).toFixed(1) + "KB";
                            }
                            return title + " : " + dataset.y.toFixed(1) + "GB";
                        }
                        return ""
                    }
                }
            },
            annotation: (noLimit === true ? undefined : {
                events: ["click", "mouseenter", "mouseleave"],
                annotations: [warningThresold, limitThresold].map((it: Thresold) => {
                    return {
                        drawTime: "afterDatasetsDraw",
                        id: it.key,
                        type: "line",
                        mode: "horizontal",
                        scaleID: "y-axis-0",
                        value: it.value,
                        borderColor: it.color,
                        borderWidth: 1,
                        label: {
                            backgroundColor: "black",
                            content: it.text,
                            enabled: true
                        }
                    }
                })
            }),
        }
    }
    return props
}
