import { EvolutionDataset } from "../EvolutionDataset"
import { ChartDataSets, ChartPoint } from "chart.js"
import { NginxRequestsCountApiEvolutionResponse } from "../../../api/MaintenanceRequests"
import { MaterialColors } from "../../../helpers/Colors";

export const getNginxRequestsCountEvolutionDataset = (data: NginxRequestsCountApiEvolutionResponse): EvolutionDataset => {
    // const dataset: ChartDataSets = {
    //     borderColor: "rgba(28,95,220,0.63)",
    //     backgroundColor: MaterialColors,
    //     label: "Codes",
    //     lineTension: 0,
    //     data: []
    // };

    let datasets = new Array<ChartDataSets>()
    
    const dates:Array<Date> = new Array<Date>()
    const labels:Array<string> = new Array<string>()

    data.forEach(it => {     
        if(it.codes !== null){
            
            const dateString:string = it.date
            const date:Date = new Date(dateString)
            dates.push(date);
            labels.push(date.toLocaleDateString());
        
            it.codes.forEach((codeInfos, _) => {
                
                
            
                let existingDataset = datasets.find(it => it.label === codeInfos.code)

                if(existingDataset){
                    (existingDataset.data as ChartPoint[]).push({
                        x: date,
                        y: Math.round(codeInfos.count)
                    })
                }else {
                    const newDataset:ChartDataSets =  {
                        borderColor: "rgba(28,95,220,0.63)",
                        backgroundColor: MaterialColors[datasets.length],
                        label: codeInfos.code,
                        lineTension: 0,
                        data: []
                    };
                    datasets.push(newDataset);

                    (newDataset.data as ChartPoint[]).push({
                        x: date,
                        y: Math.round(codeInfos.count)
                    });
                }
            })
        }
    })

    return {
        datasets: datasets,
        dates: dates,
        error: undefined,
        labels: labels,
        maxValue: undefined
    }
}