import { ChartXAxe } from "chart.js";
import {subDays} from "date-fns";
import { Interval } from "../../navigation/tabs/Tabs";

const xAxeTimeDefaultProps = (minDate: number|undefined, maxDate: number|undefined, interval: Interval, startDate: number, endDate: number, adjustEndToMidnight: boolean = true): ChartXAxe => {
    let maxDaysToDisplay = 31;
    let firstDateToDisplay = startDate
    // If startdate enddate are defined and there is less than 31 days to display
    if(startDate !== undefined && endDate !== undefined && endDate - startDate > (maxDaysToDisplay * 24 * 60 * 60 * 1000) && interval === Interval.DAY){
        firstDateToDisplay = endDate - (maxDaysToDisplay * 24 * 60 * 60 * 1000)
    }

    if(startDate === undefined && minDate && minDate > (maxDaysToDisplay * 24 * 60 * 60 * 1000) && interval === Interval.DAY){
        firstDateToDisplay = minDate
    }


    let endDateToDisplay = endDate
    if(endDateToDisplay !== undefined){
        const endDateObj = new Date(endDate)
        if(adjustEndToMidnight === true){
            endDateObj.setHours(0, 0, 0, 0)
        }
        endDateToDisplay = endDateObj.getTime()
    }

    if(firstDateToDisplay !== undefined){
        const startDateObj = new Date(startDate)
        startDateObj.setHours(0, 0, 0, 0)
        firstDateToDisplay = startDateObj.getTime()
    }

    // If interval is hour
    if(startDate !== undefined && endDate !== undefined && interval === Interval.HOUR && endDate - startDate < (2 * 24 * 60 * 60 * 1000 - 1)){
        firstDateToDisplay = startDate
        endDateToDisplay = endDate
    }

    return {
        type: 'time',
        offset: true,
        time: {
            displayFormats: {
                millisecond: 'HH:mm:ss.SSS',
                second: 'HH:mm:ss',
                minute: 'HH:mm',
                hour: "HH:00"
            },
            parser: "yyyy-MM-dd'T'HH:mm:ssz",
            tooltipFormat: getTooltipFormat(interval),
            minUnit: getMinUnit(interval)
        },
        scaleLabel: {
            display: false,
            labelString: 'Date'
        },
        ticks: {
            min: firstDateToDisplay !== undefined ? firstDateToDisplay : getRangeMin(maxDate, interval),
            max: endDateToDisplay !== undefined ? endDateToDisplay : maxDate
        }
    }
}



const getTooltipFormat = (interval: Interval) => {
    switch (interval) {
        case Interval.HOUR:
            return "ll HH:mm"
        case Interval.DAY:
            return "ll"
        default:
            return "ll HH:mm"
    }
}

const getMinUnit = (interval: Interval) => {
    switch (interval) {
        case Interval.HOUR:
            return "hour"
        case Interval.DAY:
            return "day"
        default:
            return "minute"
    }
}

const getRangeMin = (date: number|undefined, interval: Interval): number|undefined => {
    if(date === undefined){
        return undefined
    }
    switch (interval) {
        case Interval.HOUR:
            return subDays(new Date(date), 1).getTime()
        case Interval.DAY:
            return subDays(new Date(date), 30).getTime()
        default:
            return subDays(new Date(date), 30).getTime()
    }
}



export default xAxeTimeDefaultProps
