import {Component} from "react"
import { getClientUUID } from "../api/Auth"
import { getDevices, getGenieacsDevicesSummary } from "../api/DevicesRequests"
import { getOrganization } from "../api/OrganizationRequests"
import { secToTextWithoutUselessUnit, timeAgoInSeconds, checkLastNdays } from "../helpers/DateHelper"
import { getAllBusNodes } from "../helpers/factories/FactoriesHelper"
import { Mediaspot } from "../models/Mediaspot"
import { Router } from "../models/Router"
import { LoadingState } from "../navigation/LoadingState"
import classes from "../stats/Statistics.module.css"
import DeviceStateClasses from "./BusList.module.css"
import Loader from "react-loader-spinner";
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle, faRedo } from "@fortawesome/free-solid-svg-icons"


export type DeviceStateItem =  {
    busId: string
    rutxSerial: string
    mediaspotSerial: string
    contentProviders: string[]
    lastSyncServerContactReceived: string | undefined
}


export type BusNodesResponse = {
    hasMediaspot: boolean
    isAllowed: boolean
    isStore: boolean
    isVisible: boolean
    path: string
    type: "bus"
    val: string
}

interface IProps {

}

interface IState {
    // error: string | undefined
    devicesState: DeviceStateItem[],
    loadingState: LoadingState,

    sortColumn:  "busnumber" | "ms_serial" | "router_serial" | "cp" | "last_syncserver_contact",
    reverse: boolean
}

class DevicesState extends Component<IProps, IState> {
    nbBus24 = 0
    nbBus5d = 0

    constructor(props: IProps){
        super(props)
        this.state = {
            devicesState: new Array<DeviceStateItem>(),
            loadingState: LoadingState.LOADING,
            sortColumn: "last_syncserver_contact",
            reverse: false
        }
        
    }

    async componentDidMount(){
        await this.loadBus()
    }

    loadBus = async() => {
        const responses = await Promise.all([
            getOrganization(),
            getDevices("", "", "1", ["mediaspot", "router"], getClientUUID()),
            getGenieacsDevicesSummary("")
            
        ])
        // Get organization
        const organizationResponse = responses[0]
        console.log(organizationResponse)
        if(organizationResponse.error){
            this.setState({loadingState: LoadingState.ERROR})
            return
        }

        // get mediaspot and rutx
        const devicesResponse = responses[1]
        if(devicesResponse.error){
            this.setState({loadingState: LoadingState.ERROR})
            return
        }

        const genieacsDevicesResponse = responses[2]
        if(genieacsDevicesResponse.error){
            this.setState({loadingState: LoadingState.ERROR})
            return
        }

        console.log(organizationResponse)
        console.log(devicesResponse)
        console.log(genieacsDevicesResponse)

        const devicesState = new Array<DeviceStateItem>()
        const mediaspots:Mediaspot[] = devicesResponse.mediaspot.Items
        const routers: Router[] = devicesResponse.router.Items
        const genieacsDevices = genieacsDevicesResponse.data


        const busNodes: BusNodesResponse[] = getAllBusNodes(organizationResponse[Object.keys(organizationResponse)[0]], Object.keys(organizationResponse)[0]) as BusNodesResponse[]
        mediaspots.forEach(mediaspot => {
            // console.log(mediaspots)
            // Find bus id path on organization
            const node = busNodes.find(busNode => busNode.path === mediaspot.gsi1sk && mediaspot.pk.endsWith("|0"))
            if(node){
                devicesState.push({
                    busId: node.val,
                    rutxSerial: "",
                    contentProviders: [],
                    mediaspotSerial: mediaspot.serial,
                    lastSyncServerContactReceived: undefined
                })
            }
        })

        routers.forEach(router => {
            // Find bus id path on organization
            const node = busNodes.find(busNode => busNode.path === router.gsi1sk)
            if(node){
                const deviceState = devicesState.find(it => it.busId === node.val && router.pk.endsWith("|0"))
                // If device state is already set
                if(deviceState !== undefined){
                    deviceState.rutxSerial = router.serial
                }
            }
        })

        devicesState.sort((a,b) => {
            switch(this.state.sortColumn){
                case "busnumber": return (a.busId > b.busId) ? this.state.reverse ? -1 : 1 : ((b.busId > a.busId) ? this.state.reverse ? 1 : -1 : 0)
                case "cp": return (a.contentProviders > b.contentProviders) ? this.state.reverse ? -1 : 1 : ((b.contentProviders > a.contentProviders) ? this.state.reverse ? 1 : -1 : 0)
                case "ms_serial": return (a.mediaspotSerial > b.mediaspotSerial) ? this.state.reverse ? -1 : 1 : ((b.mediaspotSerial > a.mediaspotSerial) ? this.state.reverse ? 1 : -1 : 0)
                case "router_serial": return (a.rutxSerial > b.rutxSerial) ? this.state.reverse ? -1 : 1 : ((b.rutxSerial > a.rutxSerial) ? this.state.reverse ? 1 : -1 : 0)
                // case "last_syncserver_contact":  return (a.lastSyncServerContactReceived > b.lastSyncServerContactReceived) ? this.state.reverse ? -1 : 1 : ((b.lastSyncServerContactReceived > a.lastSyncServerContactReceived) ? this.state.reverse ? 1 : -1 : 0)
            }
            return (a.busId > b.busId) ? this.state.reverse ? -1 : 1 : ((b.busId > a.busId) ? this.state.reverse ? 1 : -1 : 0)
        })

        await this.setState({devicesState: devicesState, loadingState: LoadingState.LOADED})
        
        // console.log(genieacsDevicesResponse)
        this.nbBus24 = 0
        this.nbBus5d = 0
        genieacsDevices.forEach((mediaspot: any) => {
            // console.log(mediaspot.lastContactReceived)
            const deviceStateToEdit = this.state.devicesState.find(it => "FFFFFF-Mediaspot-" + it.mediaspotSerial === mediaspot.id)
            if (deviceStateToEdit) {
                if (checkLastNdays(mediaspot.lastContactReceived,2)) {
                    this.nbBus24 += 1
                }
                if (checkLastNdays(mediaspot.lastContactReceived,5)) {
                    this.nbBus5d += 1
                }
                deviceStateToEdit.contentProviders = mediaspot.cp.map((cp: {name:string}) => cp.name).join(",")
                deviceStateToEdit.lastSyncServerContactReceived = mediaspot.lastContactReceived
            }
        })

        await this.setState({devicesState: devicesState, loadingState: LoadingState.LOADED})
    }

    onColumnSortClick = (column:  "busnumber" | "ms_serial" | "router_serial" | "cp" | "last_syncserver_contact") => {        
        const devicesStateCopy = this.state.devicesState.sort((a,b) => {
            switch(this.state.sortColumn){
                case "busnumber": return (a.busId > b.busId) ? this.state.reverse ? -1 : 1 : ((b.busId > a.busId) ? this.state.reverse ? 1 : -1 : 0)
                case "cp": return (a.contentProviders > b.contentProviders) ? this.state.reverse ? -1 : 1 : ((b.contentProviders > a.contentProviders) ? this.state.reverse ? 1 : -1 : 0)
                case "ms_serial": return (a.mediaspotSerial > b.mediaspotSerial) ? this.state.reverse ? -1 : 1 : ((b.mediaspotSerial > a.mediaspotSerial) ? this.state.reverse ? 1 : -1 : 0)
                case "router_serial": return (a.rutxSerial > b.rutxSerial) ? this.state.reverse ? -1 : 1 : ((b.rutxSerial > a.rutxSerial) ? this.state.reverse ? 1 : -1 : 0)
                case "last_syncserver_contact": return a.lastSyncServerContactReceived && b.lastSyncServerContactReceived ? (a.lastSyncServerContactReceived > b.lastSyncServerContactReceived) ? this.state.reverse ? -1 : 1 : ((b.lastSyncServerContactReceived > a.lastSyncServerContactReceived) ? this.state.reverse ? 1 : -1 : 0) : 0
            }
        })
        

        this.setState({
            devicesState: devicesStateCopy,
            sortColumn: column,
            reverse: !this.state.reverse
        })

        this.forceUpdate()
    }
    

    render(){

        
        return (
            <>
                {
                    this.state.loadingState === LoadingState.LOADING ?
                    <div className={classes.StatisticsLoaderContainer}>
                            <Loader type="Oval" color="#4185F4" height={50} width={50}/>
                        </div> : null
                }
                
                {
                    this.state.loadingState === LoadingState.ERROR ?
                    <div className={classes.StatisticsErrorContainer}>
                        <label><Trans>AnErrorOccurredDuringLoading</Trans>...</label>
                        <br />
                        <button className={classes.RetryButton} onClick={() => this.loadBus()}><FontAwesomeIcon icon={faRedo} size={"sm"} color={"#404040"}/>&nbsp;&nbsp;<Trans>Reload</Trans></button>
                    </div> : null
                }
                

                <div className={[classes.ChartContainer, DeviceStateClasses.DeviceStateContainer].join(" ")}>
                    <label>Liste des bus</label>
                    <div className={classes.ChartTitleSeparator}/>
                    <div className={classes.ChartGraphContainer}>            
                        <div className={classes.StatisticsGroupContent}>
                
                            {/* <label>Devices state</label> */}
                            <table className={DeviceStateClasses.DevicesStateTable}>
                                <thead>
                                    <tr>
                                        <th onClick={() => this.onColumnSortClick("last_syncserver_contact")}>Date dernier contact</th>
                                        <th>Etat système (5 derniers jours)  <br />{this.nbBus5d} véhicules en ligne</th>
                                        <th>Etat système (2 derniers jours) <br />{this.nbBus24} véhicules en ligne</th>
                                        <th onClick={() => this.onColumnSortClick("busnumber")}>Numéro de bus</th>
                                        <th onClick={() => this.onColumnSortClick("ms_serial")}>Serial mediaspot</th>
                                        <th onClick={() => this.onColumnSortClick("router_serial")}>Serial routeur</th>
                                        <th onClick={() => this.onColumnSortClick("cp")}>Content providers</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        
                                        this.state.devicesState !== null ? this.state.devicesState.map(deviceState => {
                                            const lastSyncServerContactReceived = deviceState.lastSyncServerContactReceived ? new Date(parseInt(deviceState.lastSyncServerContactReceived) * 1000) : undefined

                                            return (
                                                <tr>
                                                    <td>{lastSyncServerContactReceived ? 
                                                        <>
                                                        {lastSyncServerContactReceived.toLocaleString()}
                                                        </> : undefined}
                                                    </td>
                                                    <td>{lastSyncServerContactReceived ? 
                                                        <>
                                                        {checkLastNdays(lastSyncServerContactReceived.getTime() / 1000,5) ? <div className={DeviceStateClasses.GreenCircle}/> : <div className={DeviceStateClasses.RedCircle}/>}
                                                        </> : undefined}
                                                        
                                                    </td>
                                                    <td>{lastSyncServerContactReceived ? 
                                                        <>
                                                        {checkLastNdays(lastSyncServerContactReceived.getTime() / 1000,2) ? <div className={DeviceStateClasses.GreenCircle}/> : <div className={DeviceStateClasses.RedCircle}/>}
                                                        </> : undefined}
                                                        
                                                    </td>
                                                    <td>{deviceState.busId}</td>
                                                    <td>{deviceState.mediaspotSerial}</td>
                                                    <td>{deviceState.rutxSerial}</td>
                                                    <td>{deviceState.contentProviders}</td>
                                                </tr>
                                            )
                                        }) : null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
                        
        )
    }
}

export default DevicesState