import {Component} from "react"
import classes from "./Menu.module.css"
import classesMenuItem from "./MenuItem.module.css"
import {Link} from "react-router-dom";

import CotentinIcon from "../../assets/img/capcotentin.svg"
import {withRouter} from "react-router-dom"
import { isAdmin, isSuperAdmin } from "../../api/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartArea, faCogs, faHome, faSlidersH } from "@fortawesome/free-solid-svg-icons";


class Menu extends Component<any, any> {

    menuItems = [
        {
            name:"Tableau de bord",
            url: "/home",
            adminRequired: false,
            icon: faHome,
            superAdminRequired: false,
            childs: [
                {
                    name: "Accueil",
                    url: "/home",
                    adminRequired: false,
                    superAdminRequired: false,
                },
                {
                    name: "Carte des véhicules",
                    url: "/map",
                    adminRequired: false,
                    superAdminRequired: false,
                },
            ]
        },
        {
            name:"Supervision",
            url: "/supervision",
            adminRequired: false,
            icon: faChartArea,
            superAdminRequired: false,
            childs: [
                {
                    name: "Portail de services",
                    url: "/supervision/portal_services",
                    adminRequired: false,
                    superAdminRequired: false,
                },
                {
                    name: "Accès internet",
                    url: "/supervision/internet_access",
                    adminRequired: false,
                    superAdminRequired: false,
                },
                {
                    name: "Réseau WiFi",
                    url: "/supervision/wifi_network",
                    adminRequired: false,
                    superAdminRequired: false,
                },
                {
                    name: "Maintenance",
                    url: "/supervision/maintenance",
                    adminRequired: false,
                    superAdminRequired: false,
                },
                {
                    name: "Liste des bus",
                    url: "/supervision/bus_list",
                    adminRequired: false,
                    superAdminRequired: false,
                }
            ]
        },
        {
            name: "Administration",
            url: "/admin/",
            adminRequired: true,
            icon: faSlidersH,
            superAdminRequired: false,
            childs: [
                {
                    name: "Gestion des affectations",
                    url: "/admin/devices",
                    adminRequired: true,
                    superAdminRequired: false,
                },
                {
                    name: "Gestion des équipements" ,
                    url: "/admin/mediaspots",
                    adminRequired: true,
                    superAdminRequired: false,
                }
            ]
        },
        {
            name: "Provisionning",
            url: "/provisionning/",
            adminRequired: true,
            icon: faCogs,
            superAdminRequired: true,
            childs: [
                {
                    name: "Mediaspots",
                    url: "/provisionning/mediaspots",
                    adminRequired: true,
                    superAdminRequired: true,
                },
                {
                    name: "Routeurs",
                    url: "/provisionning/routers",
                    adminRequired: true,
                    superAdminRequired: true,
                },
                {
                    name: "Carte SIM",
                    url: "/provisionning/sim",
                    adminRequired: true,
                    superAdminRequired: true,
                }
            ]
        }
    ]

    
    render(){
        const isUserAdmin = isAdmin()
        const isUserSuperAdmin = isSuperAdmin()
        console.log(window.location.pathname)
        return (
            
            <div className={classes.MenuContainer}>


                <img className={classes.HeaderLogo} alt={"Cotentin"} src={CotentinIcon}/>

                <div className={classes.MenuHeaderSeparator}/>

                {
                    this.menuItems.map(item => {
                        return (
                            ((item.adminRequired === true && isUserAdmin !== true) || (item.superAdminRequired === true && isUserSuperAdmin !== true)) ? null :  <div key={item.url}>
                                <div key={item.url} className={[classesMenuItem.MenuItemContainer,  window.location.pathname.startsWith(item.url) ?  classesMenuItem.MenuItemActive : undefined].join(' ')}>
                                    <Link to={item.url}><FontAwesomeIcon icon={item.icon}/>&nbsp;&nbsp;{item.name}</Link>
                                </div>
                                {
                                
                                    ((item.adminRequired === true && isUserAdmin !== true) || (item.superAdminRequired === true && isUserSuperAdmin !== true)) ? null : item.childs.map(subItem => {
                                        return <div key={subItem.url} className={[classesMenuItem.SubmenuItemContainer, window.location.pathname.startsWith(subItem.url) ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                                        <Link to={subItem.url}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{subItem.name}</Link>
                                    </div>
                                    })            
                                }
                            </div>
                        )       
                    })
                }

                
                <label className={classes.MenuAppVersion}>
                    <span>Version {process.env.REACT_APP_VERSION}</span>
                    <br />
                    <br />
                    <span className={classes.MenuCopyright}>Tous droits réservés Fastpoint 2021</span>
                </label>
                
            </div>
        )
    }
}

export default withRouter(Menu);
