import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { getRutxAuth, RutxAuthApiResponse } from "../../../api/MaintenanceRequests";
import { LoadingState } from "../../../navigation/LoadingState";
import { Area } from "../../../navigation/tree/Area";
import {Trans} from "react-i18next"
import classes from "../../Statistics.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { getAllBusNodes, getVarPath } from "../../../helpers/factories/FactoriesHelper";
import { getOrganization } from "../../../api/OrganizationRequests";
import { BusNodesResponse } from "../../../buslist/BusList";
import { getDevices } from "../../../api/DevicesRequests";
import { getClientUUID } from "../../../api/Auth";
import { Mediaspot } from "../../../models/Mediaspot";

interface IProps {
    area: Area | undefined
    nodePath: string,
    startDateTimestamp: number,
    endDateTimestamp: number
}

export const RutxAuth = (props: IProps) => {

    const [rutxAuthResponse, setRutxAuthErrors] = useState<RutxAuthApiResponse|undefined>(undefined)
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING)


    // Map with vehicle / node path
    const [pathVehiclesMap, setPathVehiclesMap] = useState<Map<string, string>>()
    
    const loadRutxAuth = async() => {
        
        setLoadingState(LoadingState.LOADING)
        const rutxAuthResponse = await getRutxAuth(props.startDateTimestamp, props.endDateTimestamp, props.nodePath, props.area?.mediaspot?.serial)
        if(rutxAuthResponse.data){
            setRutxAuthErrors(rutxAuthResponse.data)
            setLoadingState(LoadingState.LOADED)
        }else {
            setLoadingState(LoadingState.ERROR)
        }
    }

    // Used to get association between mediaspot serial and bus node name
    const loadBusNodes = async() => {
        // Get all mediaspot
        const responses = await Promise.all([
            getOrganization(),
            getDevices("", "", "1", ["mediaspot"], getClientUUID())
        ])
        const organizationResponse = responses[0]
        const devicesResponse = responses[1]
        
        if(organizationResponse.error || devicesResponse.error){
            return
        }

        const devicesPathMapping = new Map<string, string>()
        devicesResponse["mediaspot"]["Items"].forEach((mediaspot: Mediaspot) => {
            devicesPathMapping.set(mediaspot.serial, getVarPath(organizationResponse, mediaspot.gsi1sk).val)
        });

        setPathVehiclesMap(devicesPathMapping)
    }   

    // Load with component organization and bus number 
    useEffect(() => {
        loadBusNodes()
    }, [])


    // Each time props are updated, reload data
    useEffect(() => {
        loadRutxAuth()
    }, [props.startDateTimestamp, props.endDateTimestamp, props.nodePath])

    return (
        <>
         {
            loadingState === LoadingState.LOADING ?
                <div className={classes.StatisticsLoaderContainer}>
                    <Loader type="Oval" color="#4185F4" height={50} width={50}/>
                </div> : null
        }
        {
            loadingState === LoadingState.ERROR ?
                <div className={classes.StatisticsErrorContainer}>
                    <label><Trans>AnErrorOccurredDuringLoading</Trans>...</label>
                    <br />
                    <button className={classes.RetryButton} onClick={() => loadRutxAuth()}><FontAwesomeIcon icon={faRedo} size={"sm"} color={"#404040"}/>&nbsp;&nbsp;<Trans>Reload</Trans></button>
                </div> : null
        }
            
        {
            loadingState === LoadingState.LOADED && rutxAuthResponse ?

            rutxAuthResponse.length === 0 ? <div className={classes.StatisticsNoDataContainer}>
                    <label><Trans>NoDataOnThisPeriod</Trans></label>
                </div> :

                <table>
                    <thead>
                        <tr>
                            <th>Mediaspot</th>
                            <th>Bad password</th>
                            <th>Not existing user connecion attempt</th>
                            <th>Password auth succeeded</th>
                            <th>Pubkey auth succeeded</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rutxAuthResponse.map(rutxAuth => {
                                return (
                                    <tr key={rutxAuth.mediaspotSerial}>
                                        <td>{pathVehiclesMap?.has(rutxAuth.mediaspotSerial) ? pathVehiclesMap.get(rutxAuth.mediaspotSerial)  : rutxAuth.mediaspotSerial}</td>
                                        <td style={rutxAuth.badPasswordConnectionAttempt > 0 ? {backgroundColor: "rgba(255,121,10, 0.7)"} : {}}>{rutxAuth.badPasswordConnectionAttempt}</td>
                                        <td style={rutxAuth.notExistingUserConnectionAttempt > 0 ? {backgroundColor: "rgba(255,121,10, 0.7)"} : {}}>{rutxAuth.notExistingUserConnectionAttempt}</td>
                                        <td style={rutxAuth.passwordAuthSuccess > 0 ? {backgroundColor: "rgba(255,121,10, 0.7)"} : {}}>{rutxAuth.passwordAuthSuccess}</td>
                                        <td style={rutxAuth.pubkeyAuthSucceeded > 0 ? {backgroundColor: "rgba(255,121,10, 0.7)"} : {}}>{rutxAuth.pubkeyAuthSucceeded}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                : undefined
            }
        </>
    )
}