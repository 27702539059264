import L, { divIcon } from 'leaflet';
import { FullMaterialColors } from '../Colors';

export const greenBusIcon = new L.Icon({
    
    iconUrl: '/img/bus-green.svg',
    iconRetinaUrl: '/img/bus-green.svg',
    iconAnchor: [30, 76],
    popupAnchor: [0, -76],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    iconSize: new L.Point(60, 76),
    className: 'leaflet-div-icon'
});

export const redBusIcon = new L.Icon({
    iconUrl: '/img/bus-red.svg',
    iconRetinaUrl: '/img/bus-red.svg',
    iconAnchor: [30, 76],
    popupAnchor: [0, -76],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    iconSize: new L.Point(60, 76),
    className: 'leaflet-div-icon'
});

export const orangeBusIcon = new L.Icon({
    iconUrl: '/img/bus-orange.svg',
    iconRetinaUrl: '/img/bus-orange.svg',
    iconAnchor: [30, 76],
    popupAnchor: [0, -76],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    iconSize: new L.Point(60, 76),
    className: 'leaflet-div-icon'
});

export const positionIcon = (index: number) => {
    const myCustomColour = FullMaterialColors[index]

    const markerHtmlStyles = `
        background-color: ${myCustomColour};
        width: 1rem;
        height: 1rem;
        display: block;
        left: -0.5rem;
        top: -0.5rem;
        position: relative;
        border-radius: 1rem;
        border: 1px solid #FFFFFF`

    return divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 0],
        popupAnchor: [0, -10],
        html: `<span style="${markerHtmlStyles}" />`    
    })
} 

