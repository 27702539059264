import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import Select, {OptionTypeBase, OptionsType} from 'react-select'
import makeAnimated from 'react-select/animated';
import { getClientUUID } from '../api/Auth';
import { getVehicleLocationsHistory, VehicleLocationsApiResponseWithMediaspot } from '../api/BusRequests';
import { getDevices } from '../api/DevicesRequests';
import { getOrganization } from '../api/OrganizationRequests';
import { ApiDataResponse } from '../api/Request';
import { BusNodesResponse } from '../buslist/BusList';
import { getAllBusNodes } from '../helpers/factories/FactoriesHelper';
import { Mediaspot } from '../models/Mediaspot';
import { LoadingState } from '../navigation/LoadingState';
import { positionIcon } from "../helpers/map/Markers"


interface VehicleInfo {
    vehicleName: string
    mediaspotSerial: string
    locations: Array<
        {   date: string,
            location: {
                lon: string,
                lat: string
            }
        }>
    isRequested: boolean
} 


type SelectOption = {
    label: string
    value: string
}


const VehiclesMap = () => {

    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING)
    const [vehiclesInfos, setVehiclesInfos] = useState<VehicleInfo[]>([])
    const [selectOptions, setSelectOptions] = useState<{value: string, label: string}[]>([])

    useEffect(() => {
        loadVehicles()
    }, [])

    const loadVehicles = async() => {
        const responses = await Promise.all([
            getOrganization(),
            getDevices("", "", "1", ["mediaspot"], getClientUUID()),
            
        ])
        // Get organization
        const organizationResponse = responses[0]
        console.log(organizationResponse)
        if(organizationResponse.error){
            setLoadingState(LoadingState.ERROR)
            return
        }

        // get mediaspot and rutx
        const devicesResponse = responses[1]
        if(devicesResponse.error){
            setLoadingState(LoadingState.ERROR)
            return
        }



        const vehiclesInfos = new Array<VehicleInfo>()
        const mediaspots:Mediaspot[] = devicesResponse.mediaspot.Items


        const busNodes: BusNodesResponse[] = getAllBusNodes(organizationResponse[Object.keys(organizationResponse)[0]], Object.keys(organizationResponse)[0]) as BusNodesResponse[]
        mediaspots.forEach(mediaspot => {
            // Find bus id path on organization
            const node = busNodes.find(busNode => busNode.path === mediaspot.gsi1sk && mediaspot.pk.endsWith("|0"))
            if(node){
                vehiclesInfos.push({
                    vehicleName: node.val,
                    mediaspotSerial: mediaspot.serial,
                    locations: [],
                    isRequested: false
                })
            }            
        })

        setVehiclesInfos(vehiclesInfos.sort((a, b) => a.vehicleName > b.vehicleName ? 1 : -1))
    }

    const onRequestedVehiclesChanged = async(vehiclesList?: OptionTypeBase | OptionsType<OptionTypeBase> | null) => {
        
        // first remove all already saved locations
        const vehiculsInfosWithoutLocations: VehicleInfo[] = [...vehiclesInfos].map(it => {
            it.locations = []
            return it
        })
        setVehiclesInfos(vehiculsInfosWithoutLocations)


        if(vehiclesList !== null && vehiclesList){
            const responses: ApiDataResponse<VehicleLocationsApiResponseWithMediaspot>[] = await Promise.all(vehiclesList!.map((vehicle: SelectOption) => {
                return loadInfo(vehicle)
            }))


            const vehiclesInfosCopy = [...vehiclesInfos]
            
            responses.forEach((response: ApiDataResponse<VehicleLocationsApiResponseWithMediaspot>) => {
                
                if(response.data){
                    const vehicul = vehiclesInfosCopy.find(it => it.mediaspotSerial === response.data?.mediaspotSerial)
                    
                    if(vehicul){
                        vehicul.locations = response.data.data
                    }
                }
            })
            setVehiclesInfos(vehiclesInfosCopy)
            
        }
    }

    const loadInfo = async(vehicle: SelectOption) => {
        const now = new Date(Date.now()).getTime()
        const vehicleLocationsHistory = await getVehicleLocationsHistory(now - 1000 * 60 * 60 * 2, now, vehicle.value)
        return vehicleLocationsHistory
    }

    const animatedComponents = makeAnimated();

    return (
          <>
            <Select
                styles={{
                    menu: provided => ({...provided, zIndex: 9999})
                }}
                onChange={selectedOptions=> {                    
                    onRequestedVehiclesChanged(selectedOptions);
                  }}
                  placeholder="Sélectionnez un n° de véhicule"
                  openMenuOnClick={true}
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={[]}
                isMulti
                options={vehiclesInfos.map(it => {
                    return {
                        value: it.mediaspotSerial,
                        label: it.vehicleName
                    }
                })}
            />
            <div style={{width: "100%", height: "90%"}}>
                <MapContainer  style={{width: "100%", height: "100%" }} center={[49.6272996521, -1.62550997734]} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {vehiclesInfos.map((vehiculInfo, index) => { 
                        if(vehiculInfo.locations && vehiculInfo.locations.length > 0)
                            console.log("display marker for " + vehiculInfo.vehicleName)

                        return vehiculInfo.locations.map(it => {
                            return <Marker icon={positionIcon(index)} position={[Number.parseFloat(it.location.lat), Number.parseFloat(it.location.lon)]}>
                            <Popup>
                            <b>Véhicule {vehiculInfo.vehicleName}</b>
                            <br />
                            <label>{new Date(it.date).toLocaleString()}</label>
                            </Popup>
                        </Marker>
                        }
                    )}
                    )}
                    
                </MapContainer>
            </div>
            
        </>
    )
}

export default VehiclesMap