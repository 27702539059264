import { NginxRequestsCountApiResponse, VehiclesCountEvolutionApiResponse } from "../../../api/MaintenanceRequests"
import { EvolutionDataset } from "../EvolutionDataset"
import { ChartDataSets, ChartPoint } from "chart.js"
import { MaterialColors } from "../../../helpers/Colors";

export const getNginxRequestsCountDataset = (data: NginxRequestsCountApiResponse) => {
    const sortedData = data.sort((a, b) => a.status > b.status ? 1 : -1)
    const dataset: ChartDataSets = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: MaterialColors,
        label: "Nombre de requêtes",
        lineTension: 0,
        data: []
    };

    console.log(dataset)
    
    const labels:Array<string> = new Array<string>()
    
    sortedData.forEach(it => {        
        
        (dataset.data as ChartPoint[]).push({
            x: it.status,
            y: it.count
        })

        labels.push(it.status)  
    })

    return {
        datasets: [dataset],
        dates: [],
        error: undefined,
        labels: labels,
        maxValue: undefined
    }
}
