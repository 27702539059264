import { Chart, ChartConfiguration } from "chart.js"
import "chartjs-plugin-annotation"
import i18n from "../../../i18n"
import {Interval} from "../../../navigation/tabs/Tabs"
import { Thresold } from "../../Thresold"
import xAxeTimeDefaultProps from "../XAxeTimeDefaultProps"


export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number):ChartConfiguration => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    const props:ChartConfiguration = {
        type: 'bar',
        data: {},
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            layout: {
                padding: {
                  left: 0,
                  right: 0,
                  top: 15,
                  bottom: 0
                }
              },
            scales: {
                xAxes: [
                    //xAxeTimeDefaultProps(minDate, maxDate, interval, startDateTimestamp, endDateTimestamp)
                ],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('Nombre de véhicules')
                    },
                    ticks: {
                        stepSize: 5,
                        min: 0
                    }
                }]
            },
            animation: {
                duration: 1,
                onComplete: () => {
                  
                }
            }
        }
    }
    return props
}
