import { ChartConfiguration } from "chart.js"
import "chartjs-plugin-annotation"
import i18n from "../../../i18n"
import {Interval} from "../../../navigation/tabs/Tabs"
// import ChartDataLabels from 'chartjs-plugin-datalabels';


export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number):ChartConfiguration => {

    const props:ChartConfiguration = {
        type: 'bar',
        data: {},
        // plugins: [ChartDataLabels],
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('Nombre de requêtes')
                    },
                    ticks: {
                        min: 0
                    }
                }]
            }
        }
    }
    return props
}
