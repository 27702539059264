import { getClientUUID } from "./Auth"
import { ApiDataResponse, post, postRequest } from "./Request"

const getAllUrl = "/bus"
const getByIdUrl = "/bus"

export const getAll = async(path: string) => {
    await postRequest("/bus")
}

    /***
 * Get evolution of number of hits on monitoring index
 * @param startDateTimestamp first date timestamp to request data
 * @param endDateTimestamp last date timestamp to request data
 * @param nodePath organization path of request
 * @param mediaspotSerial mediaspot serial
 * @return ApiDataResponse<NginxRequestsCountApiResponse>
 */
 export const getVehicleLocationsHistory = async(startDateTimestamp: number, endDateTimestamp: number, mediaspotSerial: string): Promise<ApiDataResponse<VehicleLocationsApiResponseWithMediaspot>> => {
    const rutxAuthResponse = await post<VehicleLocationsApiResponse>("getvehiclelocationhistory", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        clientUUID: getClientUUID()
    })

    if(rutxAuthResponse.data){
        return {
            error: undefined,
            data: {
                mediaspotSerial: mediaspotSerial,
                data: rutxAuthResponse.data
            }
        }
    }else {
        return {
            error: rutxAuthResponse.error,
            data: undefined
        }
    }
} 


// -------------------
// ---- Types def ----
// -------------------

export type VehicleLocationsApiResponse = Array<{
    date: string,
    location: {
        lon: string,
        lat: string
    },  
}>


export type VehicleLocationsApiResponseWithMediaspot = {
    mediaspotSerial: string,
    data : Array<{
        date: string,
        location: {
            lon: string,
            lat: string
        },
        
    }>
}
