// ------------------
// ---- Requests ----
// ------------------

import { MediaInfos } from "../models/MediaInfos"
import { getClientUUID } from "./Auth"
import { post, ApiDataResponse } from "./Request"

/***
 * Get media ranking with medias/access count list
 */
export const getMediasRanking = async(startDateTimestamp: number, endDateTimestamp: number, nodePath: string, mediaspotSerial?: string, mediaspotSerials?: string[]): Promise<ApiDataResponse<MediaRankingApiResponse>> => {
    const mediasRanking = await post<MediaRankingApiResponse>("getmediasranking", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID(),
        mediaspotSerials: mediaspotSerials
    })

    return mediasRanking
}

/***
 * Get Presse de la Manche ranking with access count list
 */
export const getPDMRanking = async(startDateTimestamp: number, endDateTimestamp: number, nodePath: string, mediaspotSerial?: string, mediaspotSerials?: string[]): Promise<ApiDataResponse<PDMRankingApiResponse>> => {
    const ranking = await post<PDMRankingApiResponse>("getpdmranking", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID(),
        mediaspotSerials: mediaspotSerials
    })

    return ranking
}

/***
 * Get La Manche Libre ranking with access count list
 */
export const getLMLRanking = async(startDateTimestamp: number, endDateTimestamp: number, nodePath: string, mediaspotSerial?: string, mediaspotSerials?: string[]): Promise<ApiDataResponse<LMLRankingApiResponse>> => {
    const ranking = await post<LMLRankingApiResponse>("getlmlranking", {
        startDateTimestamp: startDateTimestamp,
        endDateTimestamp: endDateTimestamp,
        mediaspotSerial: mediaspotSerial,
        nodePath: nodePath,
        clientUUID: getClientUUID(),
        mediaspotSerials: mediaspotSerials

    })

    return ranking
}


/***
 * Get media infos (title, actors...) from ids 
 */
 export const getMediasInfos = async(mediasIds: string[]): Promise<ApiDataResponse<MediasInfosApiResponse>> => {
    const mediasInfos = await post<MediasInfosApiResponse>("getmediasinfos", {
        mediasIds: mediasIds,
        clientUUID: getClientUUID()
    })

    return mediasInfos
}


// -------------------
// ---- Types def ----
// -------------------

export type MediaRankingApiResponse = Array<{
    media: string,
    count: number
}>

export type PDMRankingApiResponse = Array<{
    media: string,
    count: number
}>

export type LMLRankingApiResponse = Array<{
    media: string,
    count: number
}>

export type MediasInfosApiResponse = Array<MediaInfos>
