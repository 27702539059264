import { ChartConfiguration } from "chart.js"
import "chartjs-plugin-annotation"
import i18n from "../../../i18n"
import {Interval} from "../../../navigation/tabs/Tabs"
import xAxeTimeDefaultProps from "../XAxeTimeDefaultProps"


export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number, noLimit: boolean = false):ChartConfiguration => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    const props:ChartConfiguration = {
        type: 'line',
        data: {},
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                align:"center",
                fullWidth: true,
                labels: {
                    fontColor: "black",
                    usePointStyle: true
                },
                position: "bottom"
            },
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDateTimestamp, endDateTimestamp, false)
                ],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('Paquets')
                    },
                    ticks: {
                        min: 0
                    }
                }]
            }
        }
    }
    return props
}
