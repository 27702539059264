import React from "react";

// Include the react-fusioncharts component
import ReactFC from "react-fusioncharts";

// Include the fusioncharts library
import FusionCharts from "fusioncharts";

// Include the chart type
import charts from "fusioncharts/fusioncharts.charts";

import zoomline from "fusioncharts/fusioncharts.powercharts";
import { BrowsersRepartitionApiResponse, getBrowsersRepartition } from "../api/MaintenanceRequests";
import { MaterialColors } from "../helpers/Colors";

// Include the theme as fusion
// import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, charts, zoomline);


interface IProps {
    startDateTimestamp:number
    endDateTimestamp:number
    nodePath: string
    mediaspotSerial?:string
} 

interface IState {
    browsersRepartition?: BrowsersRepartitionApiResponse
}

class MultiLevelPieChart extends React.Component<IProps, IState> {
    
    constructor(props: IProps){
        super(props)
        this.state = {
            browsersRepartition: undefined
        }
    }

    async componentDidMount(){
        const repartition = await getBrowsersRepartition(this.props.startDateTimestamp, this.props.endDateTimestamp, this.props.nodePath, this.props.mediaspotSerial);
        if(repartition.data){
            this.setState({browsersRepartition: repartition.data})
        }
    }
    
    render() {

        const chartConfigs = {
            type: "multilevelpie", // The chart type
            width: "100%", // Width of the chart
            height: "80%", // Height of the chart
            dataFormat: "json", // Data type
            dataSource: {
                // Chart Configuration
                chart: {
                    theme: "fusion",
                    showBorder: false,
                    bgAlpha: 0,
                    hoverFillColor: "#ff976a",
                    basefontsize: 9,
                    pieFillAlpha: 75
                },
                category: this.state.browsersRepartition !== undefined && this.state.browsersRepartition?.length !== 0 ? [
                    {
                        label: "Navigateurs",
                        color: "#ff976a",
                        value: 150,
                        category: this.state.browsersRepartition.map((it, index) => {
                        return {
                            label: it.browser,
                            color: MaterialColors[index],
                            value: it.count,
                            tooltext: it.browser,
                            category: it.versions.map(it => {
                                return {
                                    label: it.version,
                                    color: `${MaterialColors[index]}00`,
                                    value: it.count,
                                }
                            })
                        }
                })}] : []   
            }
        }
        return (<ReactFC {...chartConfigs} />);
    }
}

export default MultiLevelPieChart;
