import { EvolutionDataset } from "../EvolutionDataset"
import { MaterialColors } from "../../../helpers/Colors"
import { ChartDataSets, ChartPoint } from "chart.js"
import { NetworkHitsPerNetEvolutionEvolutionResponse } from "../../../api/GSMRequests"

export const getNetworkHitsPerNetEvolutionDataset = (data: NetworkHitsPerNetEvolutionEvolutionResponse): EvolutionDataset => {
    const labels:Array<string> = new Array<string>()
    const datasets = Array<ChartDataSets>()
    
    const dates:Array<Date> = new Array<Date>()

    data.map((usageForDate) => {
        const dateString:string = usageForDate.date
        const date:Date = new Date(dateString)
        dates.push(date);

        usageForDate.networks.forEach((networkInfo, index) => {
            let dataset: ChartDataSets|undefined = datasets.find(it => (it.label  === (networkInfo.network === "qmimux0" ? "4G" :  (networkInfo.network === "br-lan" ? "Mediaspot" : networkInfo.network))))
            // Check if dataset already created
            if(dataset === undefined){
                dataset = {
                    lineTension: 0,
                    borderColor: MaterialColors[index],
                    backgroundColor: MaterialColors[index] + "33", //add opacity,
                    label: networkInfo.network === "qmimux0" ? "4G" :  (networkInfo.network === "br-lan" ? "Mediaspot" : networkInfo.network),
                    data: []
                }
                datasets.push(dataset)
            }
            (dataset.data as ChartPoint[]).push({
                x: date,
                y: Math.round((networkInfo.count + Number.EPSILON) * 100) / 100
    
            })
        })
    })
        
    return {
        datasets: datasets,
        dates: [],
        error: undefined,
        labels: labels,
        maxValue: undefined
    }
}