import { ChartConfiguration } from "chart.js"
import "chartjs-plugin-annotation"
import i18n from "../../../i18n"
import {Interval} from "../../../navigation/tabs/Tabs"


export const graphProps = (dates: Date[], interval: Interval, startDateTimestamp: number, endDateTimestamp: number):ChartConfiguration => {

    const props:ChartConfiguration = {
        type: 'bar',
        data: {},
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                align:"center",
                fullWidth: true,
                labels: {
                    fontColor: "black",
                    usePointStyle: true
                },
                position: "bottom"
            },
            scales: {
                xAxes: [
                    {
                        stacked: true
                    }
                ],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('Nombre de requêtes')
                    },
                    ticks: {
                        min: 0
                    },
                    stacked: true
                }]
            }
        }
    }
    return props
}
