import classes from './App.module.css';
import Header from './navigation/header/Header';
import Menu from './navigation/menu/Menu';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Locations from "./supervision/locations/Locations"
import Mediaspots from './admin/mediaspots/Mediaspots';
import { withAuth } from './hoc/withAuth';
import { ToastProvider } from 'react-toast-notifications';
import Statistics from './stats/Statistics';
import DevicesMapping from './devicesmapping/DevicesMapping';
import InternetAccess from './supervision/internetaccess/InternetAccess';
import PortalServices from './supervision/portalservices/PortalServices';
import WifiNetwork from './supervision/wifinetwork/WifiNetwork';
import Maintenance from './supervision/maintenance/Maintenance';
import RoutersProvisionning from './superadmin/provisionning/RoutersProvisionning';
import MediaspotsProvisionning from './superadmin/provisionning/MediaspotsProvisionning';
import SimProvisionning from './superadmin/provisionning/SimProvisionning';
import Home from './home/Home';

import BusList from './buslist/BusList';
import VehiclesMap from './vehiclesmap/VehiclesMap';


const App = () => {
    return (
        <div className={classes.App}>
            <BrowserRouter>
                <Header/>
                <Menu/>
                <div className={classes.AppContent}>
                    <ToastProvider>
                        <Switch>
                            <Route path="/home" render={() => <Home/>} />
                            <Route path="/map" render={() => <VehiclesMap/>} />

                            <Route path="/supervision/internet_access" render={() => <InternetAccess/>} />
                            <Route path="/supervision/portal_services" render={() => <PortalServices/>} />
                            <Route path="/supervision/wifi_network" render={() => <WifiNetwork/>} />
                            <Route path="/supervision/maintenance" render={() => <Maintenance/>} />
                            
                            <Route path="/supervision/bus_list" render={() => <BusList/>} />

                            <Route path="/supervision/locations" render={() => <Locations/>} />
                            <Route path="/supervision/stats" render={() => <Statistics/>} />
                            <Route path="/admin/mediaspots" render={() => <Mediaspots />} />
                            <Route path="/admin/devices" render={() => <DevicesMapping/>} />
                            <Route path="/provisionning/routers" render={() => <RoutersProvisionning />} />
                            <Route path="/provisionning/mediaspots" render={() => <MediaspotsProvisionning />} />
                            <Route path="/provisionning/sim" render={() => <SimProvisionning />} />
                            

                            <Redirect exact from={"/supervision"} to={"/supervision/portal_services"} />
                            <Redirect exact from={"/admin"} to={"/admin/devices"} />
                            <Redirect exact from={"/provisionning"} to={"/provisinning/mediaspots"} />
                            <Redirect exact from={"/"} to={"/home"} />
                        </Switch>
                    </ToastProvider>
                </div>
        </BrowserRouter>
        </div>
    )
  }
  
  export default withAuth(App)
  