import React from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import classes from "./Tabs.module.css"
import {Trans, getI18n} from "react-i18next";
import {DateRangePicker} from "rsuite";
import { subDays, subHours} from "date-fns"
import { getLastWeekInterval, isToday } from "../../helpers/DateHelper";
import { ValueType } from "rsuite/lib/DateRangePicker";
import { saveRangeDate } from "../../helpers/Storage";

export interface TabItem {
    label: string,
    path: string,
}

export enum Interval {
    HOUR,
    DAY
}

export const getIntervalString = (interval: Interval): string => {
    switch(interval){
        case Interval.HOUR: return "hour"
        case Interval.DAY: return "day"
    }
}

interface IProps {
    items: TabItem[],
    onSelect: (tabItem: TabItem) => void,
    selectedTab: TabItem|undefined,
    onPeriodChange: (value: any, event: React.SyntheticEvent) => void,
    interval: Interval,
    onIntervalChanged: (interval: Interval) => void,
    startDatePeriodTimestamp: number,
    endDatePeriodTimestamp: number,

    noPadding?: boolean | undefined
}

export const Tabs = (props: IProps) => {
    let selectedTab:string|undefined = undefined

    if(!(props.selectedTab === undefined && props.items.length === 0)){
        selectedTab = props.selectedTab !== undefined ? props.selectedTab.path : props.items[0].path
    }


    const tabsItems =  props.items.map((it:TabItem) => {
        return (
            <NavLink key={it.path} to={it.path} className={classes.tabItem} style={{ textDecoration: 'none' }} onClick={() => props.onSelect(it)}>
                <label className={it.path === selectedTab ? classes.selectedTab : classes.tab}>{it.label}</label>
                {it.path === selectedTab ? <div className={classes.tabSelectedLine}/> : undefined}
            </NavLink>
        )
    });

    const onPeriodChange = (dates: ValueType, event: React.SyntheticEvent) => {
        if(dates.length >= 2){
            if(isToday(dates[1]!)){
                saveRangeDate(dates[0]!.getTime(), Date.now())
            }else {
                saveRangeDate(dates[0]!.getTime(), dates[1]!.getTime())
            }
            
            if(((dates[1]!.getTime() + 1000 * 60 * 60 * 24 - 1) - dates[0]!.getTime()) > 1000 * 60 * 60 * 24){
                props.onIntervalChanged(Interval.DAY)
            }else {
                props.onIntervalChanged(Interval.HOUR)
            }
        }
        props.onPeriodChange(dates, event)
    }

    
    const now = new Date(Date.now())
    const midnightToday = new Date(now.setHours(0, 0, 0, 0))

    return (
        <div className={[classes.tabContainer, props.noPadding === true ? classes.tabContainerNoPadding : undefined].join(" ")} >
            <div className={classes.tabContent}>


                {tabsItems}

                <div className={classes.TabsFiltersContainer}>
                    <div className={classes.DashboardDateRangeContainer}>
                        <DateRangePicker
                            placement={"bottomEnd"}
                            cleanable={false}
                            value={(props.startDatePeriodTimestamp !== undefined && props.endDatePeriodTimestamp !== undefined) ? [new Date(props.startDatePeriodTimestamp), new Date(props.endDatePeriodTimestamp)] : undefined}
                            placeholder={getI18n().t("SelectPeriod")}
                            onChange={onPeriodChange}
                            ranges={[
                                {
                                    label: i18n.t("Aujourd'hui"),
                                    value: [midnightToday, now]
                                },
                                // {
                                //     label: i18n.t("Last24Hours"),
                                //     value: [subHours(new Date(), 24), subDays(new Date(), 1)]
                                // },
                                {label: i18n.t("Last7Days"), value: [subDays(new Date(), 6), subDays(new Date(), 1)]},
                                {label: i18n.t("LastMonth"), value: [subDays(new Date(), 30), subDays(new Date(), 1)]},
                                {label: i18n.t("Semaine dernière"), value: getLastWeekInterval()}
                            ]}/>
                    </div>
                </div>
            </div>
        </div>
    )
}