import { VehiclesCountEvolutionApiResponse } from "../../../api/MaintenanceRequests"
import { EvolutionDataset } from "../EvolutionDataset"
import { ChartDataSets, ChartPoint } from "chart.js"
import { MaterialColors } from "../../../helpers/Colors";

export const getVehiclesCountEvolutionDataset = (data: VehiclesCountEvolutionApiResponse): EvolutionDataset => {
    const dataset: ChartDataSets = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: MaterialColors[1],
        label: "Nombre de véhicules",
        lineTension: 0,
        data: data.map(it => it.bus_count)
    };
    
    const dates:Array<Date> = new Array<Date>()
    const labels:Array<string> = new Array<string>()
    
    data.forEach(it => {        
        const dateString:string = it.date
        const date:Date = new Date(dateString)
        
        dates.push(date);
    
        (dataset.data as ChartPoint[]).push({
            x: date,
            y: it.bus_count

        })

        labels.push(date.toLocaleDateString())

        
    })

    return {
        datasets: [dataset],
        dates: dates,
        error: undefined,
        labels: labels,
        maxValue: undefined
    }
}