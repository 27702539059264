import { HitsCountApiResponse } from "../../../api/MaintenanceRequests"
import { EvolutionDataset } from "../EvolutionDataset"
import { ChartDataSets, ChartPoint } from "chart.js"

export const getMonitorHitsCountEvolutionDataset = (data: HitsCountApiResponse): EvolutionDataset => {
    const dataset: ChartDataSets = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: "#54b299",
        label: "Nombre de hits",
        lineTension: 0,
        data: [],
        categoryPercentage: 1.0,
        barPercentage: 0.95
    };
    
    const dates:Array<Date> = new Array<Date>()
    const labels:Array<string> = new Array<string>()
    
    data.forEach(it => {        
        const dateString:string = it.date
        const date:Date = new Date(dateString)
        
        dates.push(date);
    
        (dataset.data as ChartPoint[]).push({
            x: date,
            y: it.hits

        })

        labels.push(date.toLocaleDateString())

        
    })

    return {
        datasets: [dataset],
        dates: dates,
        error: undefined,
        labels: labels,
        maxValue: undefined
    }
}